html {
	--bg-primary: #0a192f;
	--bg-secondary: rgba(10, 25, 47, 0.85);
	--text-primary: #ccd6f6;
	--text-secondary: #8892b0;
	--accent-primary: #64ffda;
	--accent-hover: #4db3ff;
	--card-bg: rgba(255, 255, 255, 0.05);
	--shadow-color: rgba(0, 0, 0, 0.2);

	--section-background-color: var(--bg-primary);
	--image-gradient: linear-gradient(
		to bottom left,
		var(--bg-secondary),
		var(--bg-primary)
	);
	--imp-text-color: var(--accent-primary);
	--primary-color: var(--accent-primary);
	--secondary-color: var(--bg-primary);
	--text-light: var(--text-primary);
	--background-dark: var(--bg-primary);
}

.purple {
	color: var(--accent-primary) !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background-color: var(--bg-primary);
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	transition: opacity 0.4s ease-out;
}

#preloader.fade-out {
	opacity: 0;
}

#preloader-none {
	display: none;
}

.loader-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
}

.loader-text {
	color: var(--accent-primary);
	font-family: "Fira Code", monospace;
	font-size: 1.2rem;
	opacity: 0;
	animation: fadeInUp 0.5s ease-out forwards 0.5s;
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translateY(10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

#preloader img {
	width: 100px;
	height: 100px;
	animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
	0%,
	100% {
		opacity: 1;
		transform: scale(1);
	}
	50% {
		opacity: 0.8;
		transform: scale(0.95);
	}
}

#preloader-none {
	opacity: 0;
}

#no-scroll {
	overflow: hidden;
	height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
	width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	background: var(--bg-primary);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--accent-primary);
	border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--accent-hover);
	border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
	background-color: var(--bg-secondary) !important;
	transition: all 0.3s ease-out 0s !important;
	box-shadow: 0px 10px 10px 0px var(--shadow-color) !important;
	backdrop-filter: blur(15px) !important;
}

.navbar {
	position: fixed !important;
	transition: all 0.3s ease-out 0s !important;
	padding: 0.3rem 2rem !important;
	font-size: 1.2rem !important;
	background: var(--bg-secondary) !important;
	backdrop-filter: blur(15px) !important;
}

.navbar-toggler {
	padding: 0.25rem 1.5rem !important;
	position: relative !important;
	background-color: transparent !important;
	border-color: transparent !important;
}

.navbar-toggler span {
	display: block !important;
	background-color: var(--accent-primary) !important;
	height: 4px !important;
	width: 27px !important;
	margin-top: 5px !important;
	margin-bottom: 5px !important;
	transform: rotate(0deg) !important;
	left: 0 !important;
	opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
	outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
	transition: transform 0.35s ease-in-out !important;
	transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
	position: absolute !important;
	left: 12px !important;
	top: 10px !important;
	transform: rotate(135deg) !important;
	opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
	height: 12px !important;
	visibility: hidden !important;
	background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
	position: absolute !important;
	left: 12px !important;
	top: 10px !important;
	transform: rotate(-135deg) !important;
	opacity: 0.9 !important;
}

@media (max-width: 767px) {
	.navbar {
		padding: 1rem 2rem !important;
		font-size: 1.4rem !important;
		background-color: #181a27 !important;
	}
	.navbar-nav .nav-item a::after {
		display: none !important;
	}
}
.navbar-brand {
	color: var(--text-primary) !important;
	font-weight: bold;
	font-size: 1.5rem !important;
	position: relative;
	padding: 5px 10px;
	transition: all 0.3s ease;
}

.navbar-brand::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 2px solid var(--accent-primary);
	border-radius: 4px;
	opacity: 0;
	transition: all 0.3s ease;
}

.navbar-brand:hover {
	color: var(--accent-primary) !important;
}

.navbar-brand:hover::before {
	opacity: 1;
	transform: scale(1.1);
}

.logo-text {
	color: var(--accent-primary) !important;
	font-family: "Fira Code", monospace;
	font-weight: 700;
	letter-spacing: 1px;
}

.logo {
	height: 1.4em !important;
	width: 2.5em !important;
}

.navbar-nav .nav-link {
	color: var(--text-primary) !important;
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.navbar-nav .nav-link:hover {
	color: var(--accent-primary) !important;
}

.navbar-nav .nav-link.active {
	color: var(--accent-primary) !important;
}

.nav-link {
	padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
	.nav-link {
		padding: 0.7rem 1rem !important;
	}
}

.navbar-nav .nav-item {
	position: relative;
	margin-left: 20px;
}

.navbar-nav .nav-item a {
	font-weight: 400;
	transition: all 0.3s ease-out 0s;
	position: relative;
	z-index: 1;
}

.navbar-nav .nav-item a::after {
	content: "";
	position: relative;
	display: block;
	height: 2px;
	width: 0;
	border-radius: 16px;
	background: var(--accent-primary);
	bottom: 1px;
	left: 0;
	z-index: -1;
	transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
	width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
	animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
	animation-duration: 2.1s; /* Change to speed up or slow down */
	animation-iteration-count: infinite; /* Never stop waving :) */
	transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
	display: inline-block;
}

@keyframes wave-animation {
	0% {
		transform: rotate(0deg);
	}
	10% {
		transform: rotate(14deg);
	} /* The following five values can be played with to make the waving more or less extreme */
	20% {
		transform: rotate(-8deg);
	}
	30% {
		transform: rotate(14deg);
	}
	40% {
		transform: rotate(-4deg);
	}
	50% {
		transform: rotate(10deg);
	}
	60% {
		transform: rotate(0deg);
	} /* Reset for the last half to pause */
	100% {
		transform: rotate(0deg);
	}
}
#tsparticles {
	position: fixed !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	width: 100%;
	height: 100%;
}

.home-header {
	padding-top: 80px !important;
}

.home-section {
	position: relative;
	background-color: var(--bg-primary);
	padding-top: 50px;
	padding-bottom: 30px;
	overflow-x: hidden;
}

.home-content {
	padding: 0;
	min-height: calc(100vh - 150px);
	display: flex;
	align-items: center;
}

.heading {
	font-size: 2.4em !important;
	/* padding-left: 50px !important; */
}

.heading-name {
	font-size: 2.5em !important;
	padding-left: 45px !important;
}

.main-name {
	color: var(--accent-primary);
}

.Typewriter__wrapper {
	font-size: 2.2em !important;
	color: var(--accent-primary) !important;
	font-weight: 600 !important;
}
.Typewriter__cursor {
	font-size: 2.4em !important;
	color: var(--accent-primary) !important;
}

@media (max-width: 767px) {
	.Typewriter__wrapper {
		font-size: 1.4em !important;
		font-weight: 500 !important;
		position: absolute !important;
	}
	.Typewriter__cursor {
		display: none !important;
	}
}

.myAvtar {
	justify-content: center !important;
	padding-top: 9em !important;
}

@media (max-width: 767px) {
	.myAvtar {
		padding-top: 2em !important;
		padding-bottom: 2em !important;
	}
}

.home-about-section {
	position: relative;
	padding-bottom: 70px !important;
	padding-top: 70px !important;
	background-color: var(--bg-primary) !important;
}

.home-about-section::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 1px;
	background: var(--bg-primary);
}

.home-about-section .container {
	background-color: var(--bg-primary);
}

.home-about-description {
	color: white !important;
	padding-top: 100px !important;
	padding-bottom: 20px !important;
	text-align: center;
}

.home-about-body {
	padding-top: 50px;
	font-size: 1.2em !important;
	text-align: left;
}

.home-about-social {
	text-align: center !important;
	padding-top: 25px;
	color: white !important;
}

.home-about-social-links {
	justify-content: center !important;
	padding-top: 15px !important;
	display: inline-block !important;
	position: relative !important;
	padding-inline-start: 0 !important;
}

.home-social-icons {
	position: relative !important;
	display: inline-block !important;
	width: 40px !important;
	height: 40px !important;
	text-align: center !important;
	font-size: 1.2em !important;
	line-height: 2em !important;
	background: rgba(255, 255, 255, 0.972) !important;
	border-radius: 50% !important;
	transition: 0.5s !important;
}

.home-social-icons::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background: #68187a;
	transition: 0.5s;
	transform: scale(0.9);
	z-index: -1;
}

.home-social-icons:hover::before {
	transform: scale(1.1);
	box-shadow: 0 0 15px #801f95;
}

.home-social-icons:hover {
	color: #87209e;
	box-shadow: 0 0 5px #87209e;
	text-shadow: 0 0 2px #87209e;
}

.social-icons {
	display: inline-block !important;
	padding-right: 15px;
	padding-left: 15px;
}

.icon-colour {
	color: #700c86 !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
	background-color: rgb(10, 4, 22);
	bottom: 0 !important;
	padding-top: 10px !important;
	padding-bottom: 8px !important ;
}
.footer-copywright {
	text-align: center !important;
}

.footer-body {
	text-align: center !important;
}

@media (max-width: 767px) {
	.footer-copywright {
		text-align: center !important;
	}

	.footer-body {
		text-align: center !important;
	}
}

.footer h3 {
	font-size: 1em;
	color: white !important;
	margin-top: 0.5em !important;
	margin-bottom: 0.5em !important;
}
.footer-icons {
	margin-top: 0.5em !important;
	margin-bottom: 0.5em !important;
	padding: 0 !important;
}

.blockquote-footer {
	color: #a588c0 !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
	position: relative !important;
	padding-top: 150px !important;
	padding-bottom: 30px !important;
	background-image: var(--section-background-color) !important;
}

.project-card {
	padding-top: 50px !important;
	padding-bottom: 50px !important;
	padding-left: 25px !important;
	padding-right: 25px !important;
	height: auto !important;
}

.project-card-view {
	box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
	color: white !important;
	background-color: transparent !important;
	opacity: 0.9 !important;
	transition: all 0.5s ease 0s !important;
	height: 100% !important;
}
.project-card-view:hover {
	transform: scale(1.02) !important;
	overflow: hidden !important;
	box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.blog-card {
	padding-top: 50px !important;
	padding-bottom: 50px !important;
	padding-left: 25px !important;
	padding-right: 25px !important;
	height: auto !important;
}

.blog-card-view {
	background-color: transparent !important;
	box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
	color: white !important;
	transition: all 0.5s ease 0s !important;
	height: 100% !important;
}

.blog-link {
	color: white !important;
	text-decoration: none !important;
}

.blog-link:hover {
	cursor: pointer !important;
}

.blog-card-view:hover {
	transform: scale(1.02) !important;
	overflow: hidden !important;
	box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.card-img-top {
	padding: 20px !important;
	opacity: 0.8 !important;
	border-radius: 10px !important;
}

.blog-img {
	padding: 0px !important;
	opacity: 0.8 !important;
	border-radius: 0px !important;
}

.btn-primary {
	color: #fff !important;
	background-color: var(--accent-primary) !important;
	border: none !important;
	border-radius: 25px !important;
	padding: 0.8rem 2rem !important;
	transition: all 0.3s ease !important;
}

.btn-primary:hover {
	color: #fff !important;
	background-color: var(--accent-hover) !important;
	transform: translateY(-2px) !important;
}
.btn:focus {
	outline: none !important;
	box-shadow: none !important;
}
.project-heading {
	color: white !important;
	font-size: 2.3em !important;
	font-weight: 500 !important;
	padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
	position: relative;
	padding-top: 50px;
	padding-bottom: 50px;
	background: var(--bg-primary);
}

.about-content {
	margin-bottom: 3rem;
}

.about-description {
	max-width: 800px;
	margin: 0 auto;
	padding: 0 1rem;
}

.about-description p {
	font-size: 1.1rem;
	color: var(--text-secondary);
	line-height: 1.8;
	margin-bottom: 1.5rem;
}

.section-title {
	font-size: 2rem;
	color: var(--text-primary);
	margin-bottom: 2rem;
	position: relative;
	display: inline-block;
}

.section-title::after {
	content: "";
	position: absolute;
	bottom: -10px;
	left: 50%;
	transform: translateX(-50%);
	width: 60px;
	height: 3px;
	background: var(--accent-primary);
	border-radius: 3px;
}

.skill-section,
.tool-section {
	margin: 2rem auto;
	padding: 2rem 1rem;
	background: var(--card-bg);
	border-radius: 15px;
	backdrop-filter: blur(10px);
	max-width: 1200px;
}

.tech-icons {
	position: relative !important;
	padding: 1rem !important;
	margin: 10px !important;
	opacity: 0.93 !important;
	border: none !important;
	border-radius: 15px !important;
	background: var(--card-bg) !important;
	backdrop-filter: blur(8px) !important;
	box-shadow: 0 4px 16px var(--shadow-color) !important;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
	cursor: pointer;
}

.tech-icons:hover {
	transform: translateY(-7px) !important;
	background: var(--card-bg) !important;
	box-shadow: 0 0 20px var(--accent-primary) !important;
}

.tech-icon-container {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
	min-height: 100px !important;
}

.tech-icon-container svg {
	font-size: 2.5rem !important;
	color: var(--text-primary) !important;
	margin-bottom: 0.5rem !important;
	transition: all 0.3s ease !important;
}

.tech-name {
	font-size: 0.85rem !important;
	font-weight: 500 !important;
	color: var(--text-primary) !important;
	margin-top: 0.5rem !important;
	text-align: center !important;
	opacity: 0.8 !important;
	transition: all 0.3s ease !important;
}

.tech-icons:hover .tech-name {
	opacity: 1 !important;
	transform: translateY(2px) !important;
}

.tech-icons:hover svg {
	transform: scale(1.1) !important;
	color: var(--accent-primary) !important;
}

.quote-card-view {
	border: none !important;
	color: white !important;
	background-color: transparent !important;
}

.about-activity {
	list-style: none !important;
	text-align: left !important;
	padding-left: 1px !important;
}

@media (max-width: 767px) {
	.about-img {
		padding-top: 0 !important;
	}
}
/* --------- */
/* Resume */
/* --------- */

.resume-section {
	position: relative;
	padding-top: 50px;
	padding-bottom: 50px;
	background: var(--bg-primary);
}

.resume-header {
	margin-bottom: 3rem;
}

.download-button {
	display: inline-flex;
	align-items: center;
	gap: 0.5rem;
	background: transparent;
	color: var(--accent-primary);
	border: 2px solid var(--accent-primary);
	padding: 0.8rem 2rem;
	border-radius: 8px;
	font-size: 1.1rem;
	text-decoration: none;
	transition: all 0.3s ease;
	margin-top: 1.5rem;
}

.download-button:hover {
	background: var(--accent-primary);
	color: var(--bg-primary);
	transform: translateY(-2px);
}

.download-icon {
	font-size: 1.2rem;
}

.pdf-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	background: var(--card-bg);
	padding: 1.5rem;
	border-radius: 15px;
	backdrop-filter: blur(10px);
	min-height: 500px;
}

.pdf-document {
	width: 100%;
	display: flex;
	justify-content: center;
}

.pdf-page {
	max-width: 100%;
	border-radius: 8px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.page-controls {
	display: flex;
	align-items: center;
	gap: 1.5rem;
	padding: 0.8rem 1.5rem;
	background: var(--bg-secondary);
	border-radius: 25px;
	backdrop-filter: blur(10px);
	margin-top: 1rem;
}

.page-btn {
	background: transparent;
	border: none;
	color: var(--text-primary);
	font-size: 1.8rem;
	padding: 0.2rem 0.8rem;
	cursor: pointer;
	transition: all 0.3s ease;
}

.page-btn:hover:not(:disabled) {
	color: var(--accent-primary);
	transform: scale(1.1);
}

.page-btn:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.page-info {
	color: var(--text-primary);
	font-size: 0.9rem;
	min-width: 60px;
	text-align: center;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
	.pdf-container {
		padding: 1rem;
	}

	.page-controls {
		padding: 0.6rem 1rem;
	}

	.page-btn {
		font-size: 1.5rem;
	}
}

.resume .resume-title {
	font-size: 1.5em;
	font-weight: bold;
	margin-bottom: 20px;
}

.resume .resume-item {
	margin-bottom: 15px;
}

.resume {
	padding-top: 50px;
	padding-bottom: 50px;
	justify-content: center;
}

.resume-left {
	padding-right: 80px !important;
}

.resume-right {
	padding-left: 80px !important;
}

@media (max-width: 767px) {
	.resume-left {
		padding-right: 15px !important;
		padding-left: 15px !important;
	}

	.resume-right {
		padding-right: 15px !important;
		padding-left: 15px !important;
	}
}
.resume .resume-title {
	font-size: 2em;
	font-weight: 700;
	padding-top: 30px;
	padding-bottom: 30px;
}

.resume .resume-item {
	padding: 0 0 10px 25px;
	margin-top: -2px;
	border-left: 2px solid #8a49a8;
	position: relative;
}

.resume .resume-item .resume-title {
	line-height: 18px;
	font-size: 0.9em;
	background: #5234795d;
	padding: 8px 15px;
	display: inline-block;
	font-weight: 600;
	margin-bottom: 10px;
}

.resume .resume-item ul {
	padding-left: 20px;
	text-align: justify;
}

.resume .resume-item ul li {
	padding-bottom: 10px;
	list-style: none;
}

.resume .resume-item:last-child {
	padding-bottom: 0;
}

.resume .resume-item::before {
	content: "";
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50px;
	left: -9px;
	top: 0;
	background: #fff;
	border: 2px solid #8a49a8;
}

.like-item {
	padding-top: 10px !important;
	font-size: 1.1em !important;
	font-family: sans-serif !important;
}

.like-btn {
	background-color: #934cce5e !important;
	border-color: #934cce5e !important;
	padding: 0.25rem 0.98rem !important;
	border-radius: 5px !important;
	line-height: 1.4 !important;
	transition: 0.3s ease !important;
}

.like-btn:hover {
	transform: translateY(-2px) !important;
	background-color: #a24dd386 !important;
	border-color: #a24dd386 !important;
}

.animate-like {
	animation-name: likeAnimation;
	animation-fill-mode: forwards;
	animation-duration: 0.85s;
}
@keyframes likeAnimation {
	0% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}
}

.fork-btn {
	font-size: 1.1em !important;
	padding-top: 10px !important;
}

.fork-btn-inner {
	line-height: 1.4em !important;
	background-color: var(--accent-primary) !important;
	padding: 0.25rem 1.1rem !important;
	vertical-align: middle !important;
	text-align: center !important;
}

.fork-btn-inner:hover {
	transform: translateY(-2px) !important;
	background-color: var(--accent-hover) !important;
	border-color: var(--accent-hover) !important;
}
.fork-btn-inner::after {
	display: none !important;
}

/* Mejoras generales de legibilidad y coherencia */
body {
	font-family: Arial, sans-serif;
	color: #fff; /* Color de texto predeterminado */
	background-color: #0c0513; /* Color de fondo predeterminado */
}

/* Ajustes para la navegación y la experiencia del usuario */
.navbar {
	background-color: #212038d8; /* Color de fondo de la barra de navegación */
}

.navbar-nav .nav-link {
	color: #fff; /* Color de texto de los enlaces de navegación */
}

.navbar-brand {
	color: #fff; /* Color del texto del logotipo de la barra de navegación */
}

.btn-primary {
	background-color: #623686; /* Color de fondo de los botones primarios */
	border-color: #623686;
	transition: background-color 0.3s ease; /* Transición suave para los cambios de color */
}

.btn-primary:hover {
	background-color: #6d20c5d7; /* Color de fondo al pasar el ratón por encima */
}

.resume-section {
	position: relative;
	padding-top: 110px;
	padding-bottom: 30px;
	background-color: transparent; /* Eliminar el fondo negro */
	color: white;
}

.resume-pdf-container {
	display: flex;
	justify-content: center; /* Centrar el contenido horizontalmente */
	align-items: center; /* Centrar el contenido verticalmente */
	width: 100%;
	height: 100%;
}

.tech-icon-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5rem;
}

.tech-name {
	margin-top: 0.5rem;
	font-size: 0.9rem;
	color: var(--text-primary);
	opacity: 0;
	transform: translateY(10px);
	transition: all 0.3s ease;
}

.tech-icons:hover .tech-name {
	opacity: 1;
	transform: translateY(0);
}

.home-section,
.about-section,
.resume-section {
	background: var(--bg-primary) !important;
	padding-top: 150px !important;
	background-image: none !important;
}

.project-card-view {
	background: var(--card-bg) !important;
	backdrop-filter: blur(10px) !important;
	border: none !important;
	border-radius: 15px !important;
	box-shadow: 0 4px 16px var(--shadow-color) !important;
}

.footer {
	background: var(--bg-secondary) !important;
	backdrop-filter: blur(10px) !important;
	padding: 1.5rem 0 !important;
}

/* Estilos modernos para el Home */
.home-section {
	position: relative;
	background-color: var(--bg-primary);
	padding-top: 100px;
	padding-bottom: 30px;
	overflow-x: hidden; /* Prevenir scroll horizontal */
}

.home-content {
	padding: 1rem 0;
	min-height: calc(100vh - 200px);
}

.heading {
	font-size: 2.4rem;
	font-weight: 600;
	color: var(--text-primary);
	margin-bottom: 1.5rem;
	width: 100%;
	text-align: center;
	padding: 0;
}

.heading-name {
	font-size: 2.5rem;
	font-weight: 600;
	color: var(--text-primary);
	margin-bottom: 2rem;
}

.main-name {
	color: var(--accent-primary);
}

.heading-description {
	margin-bottom: 2rem;
}

.heading-description p {
	font-size: 1.2rem;
	color: var(--text-secondary);
	max-width: 600px;
	margin: 0 auto;
}

.type-writer {
	margin-bottom: 2rem;
	min-height: 50px;
}

/* Actualizar el color del typewriter */
.Typewriter__wrapper {
	font-size: 1.5rem;
	color: var(--text-primary) !important;
	font-weight: 500;
}

.Typewriter__cursor {
	font-size: 1.5rem;
	color: var(--accent-primary) !important;
}

.social-links {
	display: flex;
	justify-content: center;
	gap: 1.5rem;
	margin-top: 2rem;
}

.social-icon {
	font-size: 1.5rem;
	color: var(--text-primary) !important;
	transition: all 0.3s ease;
	padding: 0.8rem;
	border-radius: 50%;
	background: var(--card-bg);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 3.5rem;
	height: 3.5rem;
	text-decoration: none;
}

.social-icon:hover {
	color: var(--accent-primary) !important;
	transform: translateY(-3px);
	background: var(--bg-secondary);
}

/* Ajustes responsivos */
@media (max-width: 768px) {
	.heading {
		font-size: 2rem;
	}

	.heading-name {
		font-size: 2.2rem;
	}

	.heading-description p {
		font-size: 1rem;
	}

	.Typewriter__wrapper,
	.Typewriter__cursor {
		font-size: 1.3rem;
	}
}

/* Prevenir scroll horizontal en toda la aplicación */
html,
body {
	overflow-x: hidden;
	width: 100%;
	margin: 0;
	padding: 0;
}

.container-fluid {
	padding-left: 0 !important;
	padding-right: 0 !important;
	overflow-x: hidden;
}

/* Ajustar el contenedor principal */
#root {
	overflow-x: hidden;
}

/* Estilos modernos para la sección About */
.about-section {
	position: relative;
	padding-top: 100px;
	padding-bottom: 50px;
	background: var(--bg-primary);
}

.about-content {
	margin-bottom: 4rem;
}

.about-description {
	max-width: 800px;
	margin: 0 auto;
	padding: 0 1rem;
}

.about-description p {
	font-size: 1.1rem;
	color: var(--text-secondary);
	line-height: 1.8;
	margin-bottom: 1.5rem;
}

.section-title {
	font-size: 2rem;
	color: var(--text-primary);
	margin-bottom: 2rem;
	position: relative;
	display: inline-block;
}

.section-title::after {
	content: "";
	position: absolute;
	bottom: -10px;
	left: 50%;
	transform: translateX(-50%);
	width: 60px;
	height: 3px;
	background: var(--accent-primary);
	border-radius: 3px;
}

.skill-section,
.tool-section {
	margin-bottom: 4rem;
	padding: 2rem 0;
	background: var(--card-bg);
	border-radius: 15px;
	backdrop-filter: blur(10px);
}

/* Ajustes para los contenedores de tecnologías */
.tech-icons {
	margin: 15px !important;
	padding: 1.5rem !important;
	opacity: 0.93 !important;
	border: none !important;
	border-radius: 10px !important;
	background: rgba(255, 255, 255, 0.05) !important;
	backdrop-filter: blur(8px) !important;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.tech-icons:hover {
	transform: translateY(-7px) !important;
	background: rgba(255, 255, 255, 0.1) !important;
	box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2) !important;
}

.tech-icon-container svg {
	font-size: 2.5rem !important;
	margin-bottom: 1rem !important;
	transition: all 0.3s ease !important;
}

.tech-name {
	font-size: 0.9rem !important;
	font-weight: 500 !important;
	opacity: 0.8 !important;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
	.about-section {
		padding-top: 80px;
	}

	.about-description p {
		font-size: 1rem;
		text-align: left;
	}

	.section-title {
		font-size: 1.8rem;
	}

	.skill-section,
	.tool-section {
		padding: 1rem 0;
	}
}

/* Ajustar el centrado de los títulos de sección */
.heading {
	font-size: 2.4rem;
	font-weight: 600;
	color: var(--text-primary);
	margin-bottom: 1.5rem;
	width: 100%;
	text-align: center;
	padding: 0;
}

.accent {
	color: var(--accent-primary);
}

/* Ajustar el contenedor de las secciones */
.about-section,
.resume-section {
	position: relative;
	padding-top: 50px;
	padding-bottom: 50px;
	background: var(--bg-primary);
}

/* Ajustar los contenedores de contenido */
.about-content,
.resume-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 0;
}

/* Ajustar el contenedor del título */
.resume-header,
.about-header {
	width: 100%;
	text-align: center;
	margin-bottom: 3rem;
	padding: 0;
}

/* Asegurarse que los contenedores no tengan padding extra */
.container {
	padding-left: 15px;
	padding-right: 15px;
}

/* Ajustar el espaciado de las columnas */
.col,
.col-md-12 {
	padding: 0;
}

/* Estilos para el encabezado del Resume */
.resume-header-container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 3rem;
	width: 100%;
	padding: 0 1rem;
}

.cv-download-btn {
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0.6rem 1.2rem;
	background: transparent;
	color: var(--accent-primary);
	border: 1.5px solid var(--accent-primary);
	border-radius: 6px;
	font-size: 0.9rem;
	text-decoration: none;
	transition: all 0.3s ease;
	font-weight: 500;
	letter-spacing: 0.5px;
}

.cv-download-btn svg {
	font-size: 1.1rem;
	transition: transform 0.3s ease;
}

.cv-download-btn:hover {
	background: var(--accent-primary);
	color: var(--bg-primary);
	transform: translateY(-50%) translateX(-2px);
}

.cv-download-btn:hover svg {
	transform: translateY(1px);
}

/* Ajustes responsivos */
@media (max-width: 768px) {
	.cv-download-btn {
		padding: 0.5rem 1rem;
		font-size: 0.8rem;
	}

	.cv-download-btn svg {
		font-size: 1rem;
	}
}

/* Estilos para el visor de PDF */
.pdf-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	background: var(--card-bg);
	padding: 1.5rem;
	border-radius: 15px;
	backdrop-filter: blur(10px);
	min-height: 500px;
}

.pdf-document {
	width: 100%;
	display: flex;
	justify-content: center;
}

.pdf-page {
	max-width: 100%;
	border-radius: 8px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.page-controls {
	display: flex;
	align-items: center;
	gap: 1.5rem;
	padding: 0.8rem 1.5rem;
	background: var(--bg-secondary);
	border-radius: 25px;
	backdrop-filter: blur(10px);
	margin-top: 1rem;
}

.page-btn {
	background: transparent;
	border: none;
	color: var(--text-primary);
	font-size: 1.8rem;
	padding: 0.2rem 0.8rem;
	cursor: pointer;
	transition: all 0.3s ease;
}

.page-btn:hover:not(:disabled) {
	color: var(--accent-primary);
	transform: scale(1.1);
}

.page-btn:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.page-info {
	color: var(--text-primary);
	font-size: 0.9rem;
	min-width: 60px;
	text-align: center;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
	.pdf-container {
		padding: 1rem;
	}

	.page-controls {
		padding: 0.6rem 1rem;
	}

	.page-btn {
		font-size: 1.5rem;
	}
}

/* Actualizar el SVG del preloader */
.svg-loader circle {
	stroke: var(--accent-primary) !important;
}

/* Animación de entrada para la página */
.fade-enter {
	opacity: 0;
	transform: translateY(20px);
}

.fade-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
